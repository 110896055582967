import React, { useState, useCallback, useEffect } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { doc, setDoc, getDoc, collection } from "firebase/firestore";
import { useAuth } from "./components/AuthContext";
import debounce from 'lodash/debounce';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Add this for default styling

const Entry = ({ db }) => {
  const [content, setContent] = useState("");
  const { currentUser } = useAuth();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [saveStatus, setSaveStatus] = useState("Changes are saved automatically...");
  const [createdAt, setCreatedAt] = useState(null);
  const [title, setTitle] = useState("");
  const { id } = useParams(); // Get the entry ID from the URL

  const formattedDate = (date) => date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  // Debounced save function
  const debouncedSave = useCallback(
    debounce(async (newContent) => {
      if (currentUser) {
        const entryId = id || currentDate.toISOString().split('T')[0];
        const entryRef = doc(collection(db, "users", currentUser.uid, "entries"), entryId);
        try {
          const docSnap = await getDoc(entryRef);
          const now = new Date();

          // Use the selected date for createdAt if it's a new entry
          const entryDate = id ? new Date(id + 'T00:00:00') : now;

          if (docSnap.exists()) {
            const existingData = docSnap.data();
            const contentChanged = existingData.content !== newContent;
            const significantChange = contentChanged && Math.abs(existingData.content.length - newContent.length) > 100;

            // Update existing entry
            await setDoc(entryRef, {
              content: newContent,
              updatedAt: now,
              ...(significantChange && { title: null }) // Only include title field if there's a significant change
            }, { merge: true });

            if (significantChange) {
              setTitle(""); // Update local state
            }
          } else {
            // Create new entry
            await setDoc(entryRef, {
              content: newContent,
              createdAt: entryDate, // Use the selected date for new entries
              updatedAt: now,
              title: "" // New entries start with empty title
            });
            setCreatedAt(entryDate);
            setTitle(""); // Update local state
          }

          console.log("Entry saved successfully");
          setSaveStatus("All changes saved");
        } catch (error) {
          console.error("Error saving entry:", error);
          setSaveStatus("Error saving. Please try again.");
        }
      }
    }, 2000),
    [currentUser, currentDate, db, id]
  );

  const handleEditorChange = (newContent) => {
    setContent(newContent);
    setSaveStatus("Saving...");
    debouncedSave(newContent);
  };

  // Modify the useEffect hook
  useEffect(() => {
    const loadEntry = async () => {
      if (currentUser) {
        const entryId = id || new Date().toISOString().split('T')[0];
        const entryRef = doc(collection(db, "users", currentUser.uid, "entries"), entryId);
        try {
          const docSnap = await getDoc(entryRef);
          if (docSnap.exists()) {
            const entryData = docSnap.data();
            setContent(entryData.content || "");
            setTitle(entryData.title || "");
            setCreatedAt(entryData.createdAt.toDate());
            // Fix: Properly parse the date string to local time
            setCurrentDate(id ? new Date(id + 'T00:00:00') : entryData.createdAt.toDate());
            setSaveStatus("Entry loaded");
          } else {
            setContent("");
            setTitle("");
            // Fix: Properly parse the date string to local time
            setCurrentDate(id ? new Date(id + 'T00:00:00') : new Date());
            setSaveStatus("New entry");
          }
        } catch (error) {
          console.error("Error loading entry:", error);
          setSaveStatus("Error loading entry");
        }
      }
    };

    loadEntry();
  }, [currentUser, db, id]);

  return (
    <Box sx={{ position: "relative" }}>
      <Typography variant="h5" sx={{ fontWeight: 600 }} mb={2}>
        {formattedDate(currentDate)}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          padding: 1,
          ...(isPhone
            ? {
                marginBottom: 2,
              }
            : {
                position: "absolute",
                top: 0,
                right: 0,
              }),
        }}
      >
        {saveStatus}
      </Typography>
      <ReactQuill
        theme="snow"
        value={content}
        onChange={handleEditorChange}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['clean']
          ]
        }}
        style={{
          height: 'calc(100vh - 500px)',
          minHeight: '300px',
          backgroundColor: "#fff"
        }}
      />
    </Box>
  );
};

export default Entry;
